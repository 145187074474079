<template>
  <lab-task><lab-buffers-a /></lab-task>
</template>

<script>
import LabBuffersA from '../simulations/LabBuffersA';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'BuffersASIM',
  components: {LabBuffersA, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
